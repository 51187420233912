// Foundation JavaScript
// Documentation can be found at: http://foundation.zurb.com/docs

(function($) {
	
	function wowGrid(){
	    $('[data-wow-grid]').each(function(){
		    var columns;
		    if( parseInt( $(this).attr('data-wow') ) > 0){
			    columns = parseInt( $(this).attr('data-wow') );
		    }else{
		    	columns = Math.floor( $(this).innerWidth() / $(this).children().eq(1).outerWidth() );
		    }
		    $(this).children().each(function(index){
			    var delay = (index % columns *0.2) + ( Math.floor(index/columns) * 0.1);
			    if( !$(this).hasClass('wow') ){
				    if(columns === 1){
					    
					    $(this).addClass('wow fadeInUp');
				    }else{
					    $(this).addClass('wow fadeInRight');
				    }
			    }
			    $(this).attr('data-wow-delay',delay+"s");
			    
		    });
		});
	}
    wowGrid();
    $( window ).resize(function() {
        wowGrid();
    });

	$('[data-disabled]').on('click',function(e){e.preventDefault();});

    
    $('.ddl-full-width-row').addClass('expanded').addClass('collapse');
    
    //inject special media queries for interchange on retina devices
    var retinaQueries = configShared.mediaqueries.retina.replace("'","").split(",");
    configShared.breakpoints = JSON.parse( configShared.breakpoints.replace(/\s?\(\s?/i, '{"').replace(/\s?\)\s?/i, '"}').replace(/\s?(\,|\:)\s?/ig,'"$1"'));

    
    var sizes = ['smallplus','medium','large','xlarge','xxlarge','xxxlarge'];
    for( var k in sizes ){
        var mediaQuerySize = configShared.breakpoints[sizes[k]].match(/([0-9]{1,4})([a-z]{1,3})/i);
        Foundation.Interchange.SPECIAL_QUERIES[sizes[k]+'R'] = "";//"only screen and (min-width: "+configShared.breakpoints[sizes[k]]+")";
        var i = 0;
        for(var k2 in retinaQueries){
            
            if(i !== 0){
                Foundation.Interchange.SPECIAL_QUERIES[sizes[k]+'R'] += ', ';
            }
            Foundation.Interchange.SPECIAL_QUERIES[sizes[k]+'R'] += retinaQueries[k2] + ' and (min-width: '+ parseInt(mediaQuerySize[1])/2 + mediaQuerySize[2] +')';
            i++;
        }
        
    }
    
    $(document).foundation();
    
    //make interchange work with equalizer
    $(document).on('replaced.zf.interchange', 'img', function(e) {    
	    $(e.target).trigger( 'resizeme.zf.trigger');        
    });
    
    // Remove empty P tags created by WP inside of Accordion and Orbit
    jQuery('.accordion p:empty, .orbit p:empty').remove();
    
	 // Makes sure last grid item floats left
	jQuery( '.archive-grid > .columns' ).last().addClass( 'end' );
	
	// Adds Flex Video to YouTube and Vimeo Embeds
	jQuery( 'iframe[src*="youtube.com"], iframe[src*="vimeo.com"]' ).wrap("<div class='flex-video'/>");
	
	
	
    
    
    // sticky footer and padding for sticky header
    function stickyFooter(){
        var topOffset = 0;
        if($('#main-header').css('position') != 'absolute' && $('#main-header').css('position') != 'fixed' ){
        	topOffset = $('#main-header').outerHeight(true);
        }else{
	        
        }
        var bottomOffset = $('#main-footer').outerHeight(true);
        var bodyOffset = $('body').outerHeight(true) - $('body').innerHeight();
        if($('#main-header').hasClass('sticky')){
            //topOffset = $('#main-header').outerHeight(true);
            $('.page-wrapper').css('margin-top',topOffset);
        }
        
        
        $('.page-wrapper').css('min-height',$(window).innerHeight() - topOffset - bottomOffset - bodyOffset);
        
    }
    stickyFooter();
    
    $( window ).resize(function() {
        stickyFooter();
    });
    
    


    $('[data-slick]').slick();
    

    if($(".animsition").length){
        $('.wow').css('visibility', 'hidden').css('animation-name', 'none');
        $(".animsition").animsition({
            inClass: 'animsition-fade-in',
            outClass: 'animsition-fade-out',
            inDuration: 1500,
            outDuration: 800,
            linkElement: ':not(.flex-control-nav li) > a:not([target="_blank"]):not([href^=#]):not(.no-animation):not([data-scroll-target]):not([data-featherlight]):not(.wpfai-link)',
            // e.g. linkElement: 'a:not([target="_blank"]):not([href^=#])'
            loading: true,
            loadingParentElement: 'body', //animsition wrapper element
            loadingClass: 'animsition-loading',
            //loadingInner: "loading",
            browser: [
              'animation-duration',
              '-webkit-animation-duration',
              '-o-animation-duration'
            ],
            //"unSupportCss" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
            //The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
            overlay : false,
            overlayClass : 'animsition-overlay-slide',
            overlayParentElement : 'body'
        }).one('animsition.inStart', function(){
            window.setTimeout(function(){
                wow.init();
            },500);
        });
    }else{
        wow.init();
    }

// just insert a div like this:
// <div class="o-box" data-map  data-map-id="yourmapid" data-map-key="yourapikey" data-map-lat="45.514719" data-map-lon="9.229454" data-map-zoom="16"  data-map-marker-color="#1ECF92" data-map-marker-size="large" data-map-marker-symbol="circle" data-map-marker-title="Studio Up" data-map-marker-description="Via Esopo 8, Milano"></div>
    if($('[data-map]').length ){
        $('head').append('<link rel="stylesheet" href="https://api.tiles.mapbox.com/mapbox.js/v2.1.6/mapbox.css" type="text/css" />');
        $.getScript("https://api.tiles.mapbox.com/mapbox.js/v2.1.6/mapbox.js", function(){
            var lat = $(this).data('map-lat') || 45.514719, lon = $(this).data('map-lon') || 9.229454;
            $('[data-map]').each(function(){
                L.mapbox.accessToken = $(this).data('map-key');
    
                var map = L.mapbox.map($(this)[0], $(this).data('map-id') || 'examples.map-i86nkdio' ).setView([ lat , lon ], $(this).data('map-zoom') || 16);
    
                if (Modernizr.touch) {
                    map.dragging.disable();
                    if (map.tap) map.tap.disable();
                }else{
                    map.scrollWheelZoom.disable();
                }
                L.mapbox.featureLayer({
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [
                            lon,
                            lat
                        ]
                    },
                    properties: {
                        title: $(this).data('map-marker-title') || false,
                        description: $(this).data('map-marker-description') || false,
                        'marker-size': $(this).data('map-marker-size') || 'medium',
                        'marker-color': $(this).data('map-marker-color') || "#00a3d2",
                        'marker-symbol': $(this).data('map-marker-symbol') || ""
                    }
                }).addTo(map);
            });
        });
    }


    outdatedBrowser({
        bgColor: '#c6352b',
        color: '#ffffff',
        lowerThan: 'transform',
        languagePath: ''
    });

    // - - - - - - - - - - - - - - - - - - - - - - - - -
    //  Custom
    // - - - - - - - - - - - - - - - - - - - - - - - - -

    //  No parent scroll
    
    var INITIAL_Y = 0;

    $.fn.noParentScroll = function() {

        $(this).on('DOMMouseScroll mousewheel touchstart', function (ev) {

            if (ev.type === 'touchstart') {
                INITIAL_Y = ev.originalEvent.touches[0].clientY;
            }

            var $this = $(this),
                scrollTop = this.scrollTop,
                scrollHeight = this.scrollHeight,
                height = $this.height(),
                currentScroll = scrollTop + height,
                delta = ev.originalEvent.wheelDelta,
                up = delta > 0;
        
            var prevent = function () {
                ev.stopPropagation();
                ev.preventDefault();
                ev.returnValue = false;
                return false;
            };
        
            if (!up && -delta > scrollHeight - height - scrollTop) {
                // Scrolling down, but this will take us past the bottom.
                $this.scrollTop(scrollHeight);
                return prevent();
            } else if (up && delta > scrollTop) {
                // Scrolling up, but this will take us past the top.
                $this.scrollTop(0);
                return prevent();
            }
            
        });

        $(this).on( 'touchmove', function( ev ) {

            var scroll_delta = INITIAL_Y - ev.originalEvent.touches[0].clientY;
            var scroll_pos = this.scrollTop;         
            var at_bottom = (scroll_pos + $(this).height()) == this.scrollHeight;

            if ( (scroll_delta < 0 && scroll_pos === 0) || (scroll_delta > 0 && at_bottom) ){
                ev.preventDefault();
            } 
        });

    };

    var navOpen = false;

    $('#toggle-nav').click(function(event) {
        if(navOpen === false){
            $('.topbar').noParentScroll();
            navOpen = true;
        }else{
            $('.topbar').unbind('DOMMouseScroll mousewheel touchstart touchmove');
            navOpen = false;
        }
    });


    // Scrollmagic

    var controller = new ScrollMagic.Controller();
    
    function prlx(){
        if(Foundation.MediaQuery.atLeast('medium')){
            $("[data-prlx]").each(function (index, elem) {
                var duration = window.innerHeight + elem.offsetHeight;
                var distance = $(elem).data('prlx-distance');
                var tween = TweenMax.to(elem, 1,
                 { transform: 'translate3d(0, '+distance+'px, 0)', ease: Linear.easeNone }
                 );
                new ScrollMagic.Scene({
                    duration: duration,
                    triggerElement: elem,
                    triggerHook: "onEnter"
                })
                .setTween(tween)
                .addTo(controller);
            });
        }
    }

    function dropsPrlx(){
        var drops = document.getElementById("drops");
        if(Foundation.MediaQuery.atLeast('medium') && drops){
            var dropsTween = new TimelineMax ()
            .add([
                TweenMax.to("#drops_lv1", 1, { transform: "translate3d(0, -400px, 0)", ease: Linear.easeNone}),
                TweenMax.to("#drops_lv2", 1, { transform: "translate3d(0, -300px, 0)", ease: Linear.easeNone}),
                TweenMax.to("#drops_lv3", 1, { transform: "translate3d(0, -200px, 0)", ease: Linear.easeNone})
                ]);
            var dropsScene = new ScrollMagic.Scene({duration: window.innerHeight + drops.offsetHeight, triggerElement: drops, triggerHook: "onEnter"})
            .setTween(dropsTween)
            .addTo(controller);
        }
    }

    // Slick

    $('.pl__slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        draggable: false,
        swipe: false,
        touchMove: false,
        asNavFor: '.pl__slidnav'
    });

    $('.pl__slidnav').slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        asNavFor: '.pl__slider',
        dots: false,
        focusOnSelect: true,
        infinite: false,
        responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true
            }
        }]
    });


    $('.pl__slidnav .slick-slide').removeClass('slick-active');

    $('.pl__slidnav .slick-slide').eq(0).addClass('slick-active');

    $('.slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        var mySlideNumber = nextSlide;
        $('.pl__slidnav .slick-slide').removeClass('slick-active');
        $('.pl__slidnav .slick-slide').eq(mySlideNumber).addClass('slick-active');
    });

    var windowWidth = $(window).width();

    $(window).load(function() {
        setTimeout(function(){
            prlx();
            dropsPrlx();
        }, 10);
    });

    $(window).on("resize", function () {

        if ($(window).width() != windowWidth) {

            windowWidth = $(window).width();

            controller.destroy(true);
            controller = new ScrollMagic.Controller();
            prlx();
            dropsPrlx();
        }

    });


})(jQuery);
